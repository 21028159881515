<template>
  <AddonMenuItem
    title="Add On Menu Items"
    addText="Add Add On Item"
    addModalTitle="Select the item, category, or store you would like to assign add on items to:"
    addExlcusionaryText="Add Items to Exclude from Add Ons"
    addExclusionaryModalTitle="Select the item, category, or store level you would like to exclude add on items from:"
    hintText="Addon items with modifiers or sizes are not supported and should not be set, users will not be able to select sizes or modifications"
    :upsellAddonItems="currentItems"
    @handleTargetSelection="handleTargetSelection"
    @onEditItem="onEditItem"
    @onDeleteItem="onDeleteItem"
  />
</template>

<script>
import AddonMenuItem from "components/upsell/upselladdon_menu_item.vue";
import { AddonDataHelper } from "mixins/upsell.js";
import { mapActions } from "vuex";

export default {
    components: {
        AddonMenuItem,
    },
    mixins: [AddonDataHelper],

    methods: {
        handleTargetSelection(target) {
            this.$router.push({
                name: "addon-menu-item-create",
                query: {
                    targetID: target.id,
                    targetName: target.title,
                    type: target.type,
                    exclusionary: target.exclusionary,
                },
            });
        },
        onEditItem(item) {
            this.$router.push({
                name: "addon-menu-item-edit",
                params: {
                    TargetID: `${item.TargetID}${item.exclusionary ? "e" : ""}`,
                },
            });
        },
        onDeleteItem(item) {
            let [targetID, exclusionary] = item.split("-");
            let waypointID = this.$route.params.store;
            return this.deleteAddonItem({
                waypointID,
                targetID,
                exclusionary: exclusionary === "true",
            }).then(() => {});
        },
        ...mapActions("UpsellStore", ["deleteAddonItem"]),
    },
};
</script>

<style lang="scss" scoped>
</style>
