var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AddonMenuItem", {
    attrs: {
      title: "Add On Menu Items",
      addText: "Add Add On Item",
      addModalTitle:
        "Select the item, category, or store you would like to assign add on items to:",
      addExlcusionaryText: "Add Items to Exclude from Add Ons",
      addExclusionaryModalTitle:
        "Select the item, category, or store level you would like to exclude add on items from:",
      hintText:
        "Addon items with modifiers or sizes are not supported and should not be set, users will not be able to select sizes or modifications",
      upsellAddonItems: _vm.currentItems,
    },
    on: {
      handleTargetSelection: _vm.handleTargetSelection,
      onEditItem: _vm.onEditItem,
      onDeleteItem: _vm.onDeleteItem,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }